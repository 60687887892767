// Page Layout
// -----------------------------------------------------------------------------
//
// Styles for main layout
// --------------------------------------------------

a,
a:hover,
a:active,
a:visited {
	outline: none;
	text-decoration: none;
}

#main-wrapper {
overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}



#after-party-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(/web-images/after-party.jpg);
  display: block;
  height: 250px;
  width: 100%;
  max-width: 100%;
  text-indent: 100%;
  font-size: 0;
}


body {
	background-color: $white;
}

body #footer {
	margin: 0px;
}

.breadcrumb {
	margin-top: 2em;
}

a.btn-submit-session {
	background-color: $white;
}

.btn-submit-session {
	font-size: 1.4em;
	font-weight: 600;
	padding: 1em;
}

.bts-date {
	text-align: center;
	font-weight: 400;
	font-size: 1.50em;
	margin-bottom: 1.5em;
}

.bts-sidebar-row {
	border-bottom: dotted 1px $white;
}

.bts-sidebar-row-2 {
	margin-top: 20px;
}

.bts-title {
	font-size: 2em;
	font-weight: 500;
	text-align: center;
	margin-top: -0.50em;
}

.container {
	background-color: #fff;
}

#col-1-action {
	text-align: center;
	@include breakpoint (md) {
		float: left;
		font-size: 1.1em;
		text-align: left;
		width: 70%;
	}
}

#col-2-action {
	margin: 2em 0em;
	text-align: center;

	@include breakpoint (md) {
		float: right;
	}
}

.conf-date {
	font-size: 1.7em;
	font-weight: 400;
	@include breakpoint (sm) {
		font-size: 2em;
	}
}

.conf-date-pane {
	background-color: $dcatl-orange;
	color: $white;
	padding: 0.5em 1em 3em 1em;
	position: relative;
	margin-left: -15px;
	margin-right: -15px;
	@include breakpoint (md) {
		padding-bottom: 6em;
	}
}

.l-content-top {
	padding: 1em 0em 3em 0em;
	background: $gray-lightest;
	text-align: center;

	.container {
		background: transparent;
	}
}

#content.container {
	padding-bottom: 2em;
}


.fa-rotate {
	@include rotate (45deg);
}

.fa-ticket {
	font-size: 10em;
	margin: -30px 0px -50px 0px;
	text-align: center;
	width: 100%;
	@include breakpoint (sm) {
		font-size: 15em;
	}
}

.footer {
	background-color: $dcatl-green;
}

.footer .container {
	background-color: $dcatl-green;
	color: $white;
	padding: 2em 1em;
	a {
		color: $white;	
		font-weight: 600;	
	}
}

.l-navbar {
	background: $dcatl-green;
}

.l-header-image img {
	object-fit: cover;
	width: 100%;
}

.header .container {
	background: $dcatl-green;
	padding-bottom: 1em;
	padding-top: 1.5em;
	@include breakpoint (md) {
		padding-top: 2.0em;
	}
}

.header-image.container {
	background-color: $dcatl-orange;
	padding: 0px 0px 5px 0px;
	@include breakpoint (sm) {
		padding: 0px !important;
	}
}

.hm-twitter-feed {
	padding-bottom: 2em;
}

iframe {
	max-width: 100%;
}

img {
	max-width: 100%;
}
 
video {
  max-width: 100%;
  height: auto;
 }


.content .container {
	padding-bottom: 1.5em;
}

.page-header {
	padding-top: 1.5em;
}

.pane-commerce-cart-cart {
	color: $gray-light;
	border: solid 2px $gray-light;
	font-weight: 400;
	margin-bottom: 2em;
	padding: 1em;
	h4 {
		font-size: 2em;
		font-weight: 400;
		margin-bottom: 1em;
		margin-top: 0px;
		text-align: center;
	}
}

#mc_embed_signup {
	background: transparent !important;
	font: inherit !important;
}

#mc_embed_signup_scroll {
	background-color: $dcatl-green;
	padding: .5em 2em;
	color: white;
	margin-bottom: 2em;
}

.more-link,
.btn-default {
	clear: both;
	margin: 2em 0em;
	a {
		background-color: $dcatl-orange;
		color: $white;
		font-weight: 400px;
		margin-bottom: 2em;
		padding: 1em;
	}
	a:hover {
		background-color: tint($dcatl-orange, 15%);
	}
}
  

 .radix-moscone-flipped .col-md-3 {
 	@include breakpoint (md) {
 		width: 40%;
 	}

 	@include breakpoint (lg) {
 		width: 35%;
 	}
 }

  .radix-moscone-flipped .col-md-9 {
  		@include breakpoint (md) {
 		width: 60%;
 	}
 	@include breakpoint (lg) {
 		width: 65%;
 	}
 }


.register-now-text {
	font-size: 2em;
	font-weight: 400;
	float: left;
	width: 100%;
	text-align: center;
}

.sidebar-contact {
	text-align: center;
	color: $dcatl-orange;
	border: solid;
	padding: 0.75em;
	font-size: 1.3em;
	margin: 1.5em 0em;
}

.sidebar-newsletter {
	background-color: $dcatl-green;
	color: white;
	margin-bottom: 2em;
	padding: .75em;

}

.sidebar-registration {
 	background-color: $dcatl-green;
 	margin: 2em 0em;
 	padding: 2em 1em;
 	a {
 		color: $white;
 	}
 }

  .sidebar-video {
  display: block;
  width: 100% !important;
  height: 100% !important;
  margin: 2em 0em;
  padding: 0;
}


.sidebar-bts {
	@extend .sidebar-registration;
	color: $white;
	background-color: $dcatl-orange;
	a {

	}
	h2 {

	}
}


.sidebar-bts-register {
	background-color: $white;
	margin-bottom: 2em;
}

.sidebar-bts-register-2 {
	background-color: $white;
	margin-bottom: 0px;
}

a.btn.sidebar-bts-register {
		color: $dcatl-orange;
}

a.btn.sidebar-bts-register-2 {
	color: $dcatl-orange;
}

.sidebar-getstarted-title {
	font-size: 1.2em;
	margin-top: 10px;
}

.sidebar-summit-title {
	font-size: 1.2em;
}

.sidebar-tweets .twitter-status {
	font-size: 0.75em;
}

.l-sub-header {
	background-color: $dcatl-orange;
}

#sub-header {
	display: none;
	overflow: auto; 
	@include breakpoint (sm) {
		display: block;
	}
}

#hm-sub-header {
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 1.5em;
	padding: 1em;
	@include breakpoint (sm) {
		overflow: hidden;
	}
}

.sub-header-call,
.sub-header-call.container {
	box-sizing: border-box;
	background-color: $dcatl-orange;
	padding-bottom: 1em;
	padding-top: 1em;
	font-size: 0.85em;
	color: $white;
}

.tabs {
	margin-top: 1.5em;
}

.video-grid {
	width: 100%;
}



