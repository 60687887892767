// Styles for Tabs, Messages, ..etc
// -----------------------------------------------------------------------------
#messages {
	margin-top:  0.75em;
}

.footable  {
	td.active,
	tr.even,
	tr.odd {
		background-color: $gray-lightest;
	}
}


ul.quicktabs-tabs.quicktabs-style-nostyle {
	padding: 1em 0em;
	text-align: center;
}

ul.quicktabs-tabs > li > a {
	display: block;
	background-color: $brand-success;
	color: $white;
	font-size: 1.1em;
	padding: 1em 3em;

	@include breakpoint (xs) {
		display: inherit;
	}
	
}
