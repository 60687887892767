// Styles for Taxonomy
// -----------------------------------------------------------------------------
// Sponsorship Levels Page 

.fa-trophy,
.fa-user,
.fa-heart {
	float: right;
	font-size: 6em;
}

.pane-webform-client-block-20 {
	background-color: $dcatl-green;
	clear: both;
	padding: 1em;
	.pane-title {
		color: $white;
		font-size: 1.8em;
		font-weight: 400;
		text-align: center;
	}
	label {
		color: $white !important;
	}
}


.pane-sponsor-levels-panel-pane-1 .views-row {
	border-bottom: solid 1px $gray-light;
	padding: 2em 0em;
}

.pane-sponsor-levels-panel-pane-1 .views-row-first {
	padding-top: 0px;

}


.pane-sponsor-levels-panel-pane-1 .views-row-last {
	border-bottom: none;
}