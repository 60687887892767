// Styles for panels and panes.
// -----------------------------------------------------------------------------
.pane-home-sponsor-footer,
.pane-footer-sponsor-by-event {
	.pane-title {
		@extend h2;
		text-align: center;
	}
}

.flex-sponsor-container {
	.pane-title {
		@extend h3;

	}
}