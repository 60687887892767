// Styles for Menus and Navs
// -----------------------------------------------------------------------------

// Menu
// Styles for Menus and Navs
// --------------------------------------------------


body #header {
	margin-top: 0px;
	position: relative;
	z-index: 500;
}

#logo-img-nav {

	@include breakpoint(xs) {
		background-image: url(/web-images/aduglogomobile.png);
		height: 100px;
		margin-left: -15px;
		margin-top: -25px;
		text-indent: -5000px;
		position: relative;
		width: 90px;
	}

	@include breakpoint(sm) {
		display: block;
		background-image: url(/web-images/aduglogomobile.png);
		height: 100px;
		margin-top: -20px;
		text-indent: -5000px;
		position: absolute;
		width: 90px;
	}
}


#logo-text {
	margin-top: 12px;

	@include breakpoint (sm-only) {
		float: right;
	}

	@include breakpoint (sm) {
		font-size: 2.5rem;
		margin-left: 10rem;
		margin-top: 0;
	}
}


.navbar {
	margin-bottom: 0px;
}

.navbar-brand {
	font-weight: 400;
	padding: 0px;
	@include breakpoint (sm) {
		font-size: 2.5em;
	}

}

li.navbar-nav {
	a,
	a:active, {
		background-color: transparent;
	}
}

.navbar-nav {
	font-size: 1.5em;
	font-weight: 400;
	margin: 0px -15px;
	@include breakpoint (xs) {
		border: solid 1px $dcatl-orange;
	}
	@include breakpoint (sm) {
		float: right;
	}
}


.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > li > a {
	background-color: transparent;
	@include breakpoint (xs) {
		border-bottom: solid 1px $dcatl-orange;
		color: $dcatl-orange;
		background-color: white;
	}
}

.navbar-default .dropdown-menu {
	border-bottom: solid 1px $dcatl-orange;
	color: $dcatl-orange;
	background-color: white;
}

.navbar-default .dropdown-menu > li > a {
	border-bottom: solid 1px $dcatl-orange;
	color: $dcatl-orange;
	font-size: 1.2em;
}

.navbar-default .dropdown-menu > li > a:hover {
	background-color: $dcatl-orange;
	color: $white !important;
}

.navbar-default .dropdown-menu > li > a,
.navbar-default .dropdown-menu > li > a:focus {
	color: $dcatl-orange;
}

.active-trail .active {
	background-color: $white ;
	color: $dcatl-orange;
}

li.dropdown-menu {
	a,
	a:active {
		background-color: $white;
		color: $dcatl-orange;
	}

}


.navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, {
	background-color: $white;
		color: $dcatl-orange !important;
}

#navbar-collapse {
	@include breakpoint (xs) {
		background-color: $white;
		a:hover {
		background-color: tint($dcatl-orange, 15%);
		}
	}
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
	color: $dcatl-orange;
}


//User Menu Header
.user-menu-header {
	@include breakpoint (sm) {
		font-size: 1.1em;
		position: absolute;
		right: 1em;
		top: -2em;

		li {
			display: inline-block;
			float: left;
		}

		a {
			color: $dcatl-orange;
			background-color: $white;
			padding: 0px;
		}

		li > a {
			padding: 5px 7px;
		}
	}
}
