 
// Styles for Nodes
// --------------------------------------------------

.pane-node-content  {
	h4 {
		font-size: 1.5em;
	}
}


//Session Node

.views-field-field-evttime-date-1 {
	background-color: $dcatl-orange;
	color: $white;
	font-size: 1.5em;
	font-weight: 500;
	padding: 0.75em;
	text-align: center;
}

.view-cod-schedule .footable-first-column {
	width: 38%;
}


.node-type-session {
	.pane-node-body {
		@include breakpoint (md) {
    	max-width: 80%;
    }
	}
}


//
// Styles for individual pages
// --------------------------------------------------

//Homepage Styles 
//-------------------------------

.buy-ticket {
	display: none;
	font-size: 1.4em;
	font-weight: 600;
	text-align: center;
	z-index: 2;
	
	a {
		background-color: $dcatl-orange;
		color: $white;
		padding: 1em;
	}
	a:hover {
		background-color: tint($dcatl-orange, 15%);
		text-decoration: none;
	}

	@include breakpoint (sm) {
		display: block;
		position: absolute;
		right: 2%;
		top: 3em;
	}
	
}

.flex-control-nav {
	display: none;
	margin-bottom: 10px;
	@include breakpoint (md) {
		display: block;
	}
}

.flex-control-paging li a {
	background-color: $white;
	padding: 1em;
}

.flex-control-paging li a.flex-active,
.flex-control-paging li a:hover {
	background-color: $dcatl-green;
}

.pane-homepage-slideshow-panel-pane-1 {
	margin-left: -15px;
	margin-right: -15px;
}

.flexslider {
	background:none;
	border: none;
	box-shadow: none;
	margin: 0px;
}



.flex-control-nav {
	bottom: 0px;
	z-index: 50px;
}



.footer-sponsors,
.hm-sponsors  {
	text-align: center;
	padding-bottom: 2em;
	@include breakpoint (sm) {
		width: 33%;
		float: left;
	}
	h4 {
		font-size: 1.8em;
	}
	li {
		list-style-type: none;
	}
}

.hm-videos iframe {
	width: 100%;
	@include breakpoint (md) {
		height: 225px;
	}
}

.hm-videos .pane-title {
	font-size: 38px;
}

.flex-sponsor-container,
.flex-video-container {
	padding: 0;
	text-align: center;
	width: 100%;
	display: flex;
	flex-direction:row;
	flex-wrap:wrap;
	justify-content:space-around;
	align-items: center;
}

.flex-sponsor-container  {
	.pane-title {
		text-align: center;
		width: 100%;
	}
	ul li {
		margin: 0px;
		padding: 1.6em;

	}

	li {
		display: inline-block;
	    flex: 0 0 25%;
	    align-self: stretch;
	}
}

.flex-video-item {
	display: inline-block;
    flex: 0 1 auto;
    align-self: auto;
    padding: 1em 0;
    @include breakpoint (md) {
    	width: 45%;
    }
    
}

.hm-featured-speakers {
	h2 {
		margin-bottom: 1em;
	}
}

.home-content {
	padding: 2em 0em;

	.pane-dcatl-default-conference-panel-pane-1 {
		.pane-title {
			margin: 0px 0px 0.5em 0px;
		}
	}
	

	.date-display-range {
		display: none;
	}
} /// End of HomeContent Class

.pane-home-sponsor-footer  {
	margin-top: 2em;
} 


.pane-tweets-panel-pane-1 {
	h4 {
		font-size: 1.5em;
	}
}


.views-field.views-field-field-slideshow-image-cropped img {
	height: auto;
}

.slideshow {
	color: $white;
	position: absolute;
	top: 10%;
	width: 100%;

	@include breakpoint (sm) {
		top: 40%;
	}
}

.slide-text {
	background-color: $transparent-textplate;
	padding: 20px;
	position: absolute;
	width: 100%;
	@include breakpoint (sm) {
		font-size: 1.2em;
	}

	.container {
		background: transparent;
	}
}

.slide-title {
	font-size: 1.2em;
	margin-top: 0px;
	@include breakpoint (sm) {
		font-size: 2em;
	}
}

.slide-description {
	display: none;
	@include breakpoint (md) {
		display: block;
		font-size: 0.75em;
	}
}

//Sponsors Page 

.sponsors-page {
	.pane-title {
		background-color: $dcatl-orange;
		color: $white;
		margin-bottom: 1.5em;
		padding: 0.5em;
		text-align: center;
	}
	
	
}


.sponsor-item {
	h4 {
		@extend h3;
		text-align: center;
	}
	ul {
		padding: 0px;
		text-align: center;
	}

	ul li {
		list-style-type: none;
		margin: 0;
		padding: 2em;
		zoom: 1;
	}

	 @include breakpoint (sm) {

		ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
		}

		ul li {
			flex: 0 0 33%;
			
		}

	}
}


//Schedule Page Styles 
//-------------------------------
//

.schedule-item {

	.views-field-field-experience,
	.views-field-og-vocabulary {
		display:none;
	}
	
}


.date-display-range {
	@extend h2;
	color: $white;
	background-color: $dcatl-orange;
	padding: 0.5em;
	text-align: center;
}

.schedule-page-pane {
   span {

   	&.views-label {
		float: left;
		margin-right: 1rem;
	}

   }
	
	
	.views-row-odd {
		background-color: #f9f9f9;
		margin: 2em 0em;
		padding: 2em;

	}

	.views-row-even {
		background-color: #f7f7f7;
		margin: 2em 0em;
		padding: 2em;

	}
}



.schedule-page-pane   {
	.session .views-field-body {
		@extend .collapse;
	}

}	

 
.schedule-day-menu   {

	h3 {
		margin-top: 0px;
	}

	li a {
		color: white;
		background-color: $dcatl-green;
		font-size: 1.1em;
		margin: 1em auto;
		max-width: 400px;
		text-align: center;

		&:active,
		&:focus,
		&:hover {
			background-color: darken($dcatl-green, 5%);
		}
	}
}

.view-cod-schedule-content .footable-last-column {
	min-width: 110px;
}
