// Styles for blocks.
// -----------------------------------------------------------------------------

// Featured Speakers // 
// 

.hm-featured-speakers {
	.block__title {
		font-size: 2em;
		color: $dcatl-orange;
		margin: 0.5em 0em 1em 0em;
	}
}


.hm-profile-name {
	font-size: 1.25em;
	color: $gray;
}

.hm-profile-org {
	color: $gray;
}

.hm-session-title {

}
